/* eslint-disable */
import React from 'react';
import { graphql } from 'gatsby';
import ExtendedContent from '../components/extendedContent';
import Seo from '../components/seo/Seo';

const SinglePage = ({ data: { allData, allArticles }, pageContext }) => {
	const activeSite = pageContext?.locale || '';
	const allModules = allData?.nodes?.[0]?.chooseModule || '';
	const seoData = allData?.nodes?.[0]?.seoSettings || '';
	const seoGlobal = pageContext?.seoGlobal?.data?.datoCmsSite?.globalSeo || '';
	const isHomePage = pageContext?.isHomePage || false;
	const preparedSitemapSimplify = pageContext?.preparedSitemapSimplify || [];
	const globals = pageContext?.globals?.data?.globals || '';

	return (
		<>
			<Seo seoData={seoData} seoGlobal={seoGlobal} />
			<ExtendedContent
				data={allModules}
				activeSite={activeSite}
				allArticles={allArticles}
				isHomePage={isHomePage}
				pageContext={pageContext}
				globals={globals}
				preparedSitemapSimplify={preparedSitemapSimplify}
			/>
		</>
	);
};

export default SinglePage;

export const query = graphql`
	query ($id: String, $locale: String) {
		allData: allDatoCmsSinglePage(filter: { id: { eq: $id } }) {
			nodes {
				singlePageName
				slug
				seoSettings {
					title
					description
					twitterCard
					image {
						url
					}
				}
				chooseModule {
					__typename
					...moduleChooseFeaturedArticle
					...modulePhotoHeaderButton
					...moduleHeaderDescriptionButtonGallery
					...moduleAnimatedImageWithInfoBlocks
					...moduleHomeBanner
					...moduleTopBanner
					...moduleSeeAlso
					...errorPageModule
					...mapWithShipsInPort
					...moduleTable
					...moduleHeaderWithDescription
					...dropdownList
					...videoMiniatures
					...moduleFormContact
					...mapWithFixedPoints
					...photoDescriptionTemplate
				}
			}
		}
		allArticles: allDatoCmsArticle(filter: { locale: { eq: $locale } }, sort: { fields: meta___createdAt, order: DESC }, limit: 12) {
			nodes {
				id
				singlePageName
				cardHeader
				cardDescription
				buttonText
				slug
				meta {
					createdAt
				}
				cardPhoto {
					gatsbyImageData
					alt
				}
			}
		}
	}
`;
